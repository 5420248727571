.Homepage {
    overflow: hidden;
    position: relative;
}

.Homepage * {
    font-family: 'Raleway', sans-serif;
}

.Homepage .Wave {
    display: inline-block;
    max-width: 110px;
    max-height: 110px;
}

.Homepage h1 {
    font-size: 70px;
}

.Homepage h2 {
    font-size: 48px;
    padding-top: 10px;
}

.Homepage h3 {
    font-family: 'Open Sans', sans-serif;
    padding-top: 32px;
    font-size: 38px;
}

@keyframes pulsate {
    0% {
        transform: scale(0.98);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(0.98);
    }
}

.Homepage .NameWrapper {
    display: inline-block;
}

.Homepage .NamePrefix,
.Homepage .Name {
    display: inline-block;
}

.Homepage .Name {
    color: #4f7fd6;
    animation: pulsate 2s ease-in-out infinite;
    cursor: pointer;
}

.Homepage .Avatar {
    max-width: 400px;
    max-height: 400px;
    margin-left: auto;
    margin-right: auto;
}

.About {
    overflow: hidden;
}

.About h1 {
    font-size: 70px;
}

.About .Introduction1 {
    padding-top: 20px;
    font-size: 30px;
    max-width: 50vw;
    word-wrap: break-word;
}

.lottie {
    display: grid;
    grid-template-columns: auto auto;
}

@media only screen and (min-device-width : 320px) and (max-device-width: 480px) {
    .lottie {
        display: grid;
        grid-template-columns: auto;
    }
}


.About .Introduction2 {
    padding-top: 20px;
    float: right;
    font-size: 30px;
    max-width: 50vw;
    word-wrap: break-word;
}

.About .Name {
    color: #4f7fd6;
}

.About section {
    width: 100%;
    -webkit-transform: skew(0, -4.76deg) translateY(calc(50vw/-12));
    -ms-transform: skew(0, -4.76deg) translateY(calc(50vw/-12));
    transform: skew(0, -4.76deg) translateY(calc(50vw/-12));
    min-height: calc(33vh - (110vw/-12));
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #000;
}

.About .skills-icons {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 75vw;
    margin: 0 auto;
}

.About .skills-name1 {
    font-family: 'Alfa Slab One', cursive;
    color: #fff;
    font-size: 60px;
}

.About .skills-name2 {
    font-family: 'Alfa Slab One', cursive;
    color: #4f7fd6;
    font-size: 64px;
}

.About .skills-icons li {
    transition: 0.2s ease-in-out;
    color: #fff;
    margin: 2.5rem 2.5rem;
}

.About .skills-icons li:hover {
    transform: scale(1.25);
}

.About .Education {
    position: relative;
}

.About .Education:after {
    content: "";
    position: absolute;
    left: 2rem;
    top: 110%;
    width: 33%;
    height: 2px;
    background-color: black;
    transition: width 0.6s ease-in-out;
}

.About .Education:hover:after {
    width: 75%;
}

.About .Education .education-card .UniLogo {
    width: 120px;
    height: 120px;
}

.About .Education .education-card ul {
    margin: 0;
    padding-left: 30px;
    list-style: none;
}

.About .Education .education-card ul li {
    list-style-type: disc;
}

.About .Education .education-card .break {
    flex-basis: 100%;
    height: 0;
}

.Projects {
    overflow: hidden;
}

.Projects .carousel.carousel-slider .control-arrow {
    padding: 10px;
    background-color: #000;
}

.Projects .carousel.carousel-slider {
    overflow: inherit;
}

.Projects .carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
    background-color: transparent;
    right: 0px;
}

.Projects .carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    left: 0px;
}

.Projects .carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 1;
}

.Projects .carousel .control-next.control-arrow:before {
    content: '';
    border: solid #0135AD;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.Projects .carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #0135AD;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.push {
    height: 8vh;
}